/* IMPORTANT
   When this file is updated, the browser cache should be reloaded.
   This file will have changed and there is nothing that will evict it
   from cache.
*/

/* tinyMCE format examples
   Styles in will be automatically added to the top level of the formats menu.
   Condition is that the file is named tinymce-formats.css and registered in the TinyMCE controlpanel
   File is currently not included in barceloneta-base somehow, so I copied it here.
*/

.discreet {
    opacity: 0.7;
}

.highlight-inline {
    background: #FFFE14;
}

p.highlight-paragraph {
    background: #FFFE14;
    padding: 1rem;
}

img.autoscale {}

img.inset-right,
figure.inset-right {}

video.autoscale,
iframe.autoscale,
embed.autoscale,
figure.autoscale,
audio.autoscale {}


/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRpbnltY2UtZm9ybWF0cy5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEiLCJmaWxlIjoidGlueW1jZS1mb3JtYXRzLmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi8qIElNUE9SVEFOVFxuICAgV2hlbiB0aGlzIGZpbGUgaXMgdXBkYXRlZCwgdGhlIGJyb3dzZXIgY2FjaGUgc2hvdWxkIGJlIHJlbG9hZGVkLlxuICAgVGhpcyBmaWxlIHdpbGwgaGF2ZSBjaGFuZ2VkIGFuZCB0aGVyZSBpcyBub3RoaW5nIHRoYXQgd2lsbCBldmljdCBpdFxuICAgZnJvbSBjYWNoZS5cbiovXG5cbi8qIHRpbnlNQ0UgZm9ybWF0IGV4YW1wbGVzXG4gICBTdHlsZXMgaW4gd2lsbCBiZSBhdXRvbWF0aWNhbGx5IGFkZGVkIHRvIHRoZSB0b3AgbGV2ZWwgb2YgdGhlIGZvcm1hdHMgbWVudS5cbiAgIENvbmRpdGlvbiBpcyB0aGF0IHRoZSBmaWxlIGlzIG5hbWVkIHRpbnltY2UtZm9ybWF0cy5jc3MgYW5kIHJlZ2lzdGVyZWQgaW4gdGhlIFRpbnlNQ0UgY29udHJvbHBhbmVsXG4gICBGaWxlIGlzIGN1cnJlbnRseSBub3QgaW5jbHVkZWQgaW4gYmFyY2Vsb25ldGEtYmFzZSBzb21laG93LCBzbyBJIGNvcGllZCBpdCBoZXJlLlxuKi9cblxuLmRpc2NyZWV0IHtcbiAgICBvcGFjaXR5OiAwLjc7XG59XG5cbi5oaWdobGlnaHQtaW5saW5lIHtcbiAgICBiYWNrZ3JvdW5kOiAjRkZGRTE0O1xufVxuXG5wLmhpZ2hsaWdodC1wYXJhZ3JhcGgge1xuICAgIGJhY2tncm91bmQ6ICNGRkZFMTQ7XG4gICAgcGFkZGluZzogMXJlbTtcbn1cblxuaW1nLmF1dG9zY2FsZSB7fVxuXG5pbWcuaW5zZXQtcmlnaHQsXG5maWd1cmUuaW5zZXQtcmlnaHQge31cblxudmlkZW8uYXV0b3NjYWxlLFxuaWZyYW1lLmF1dG9zY2FsZSxcbmVtYmVkLmF1dG9zY2FsZSxcbmZpZ3VyZS5hdXRvc2NhbGUsXG5hdWRpby5hdXRvc2NhbGUge31cblxuIl19 */